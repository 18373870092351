import {Injectable} from '@angular/core';
import {ApiCommonResponseMessages} from '@common/enum/apiResponseMessages.enum';
import {MaterialCustomClasses} from '@common/enum/materialCustomClasses.enum';
import {JSONParse} from '@common/statics/jsonParse';
import {GlobalMessageCommon} from '@common/ts/globalMessageCommon';
import {StringObjectDTO} from '@common/ts/interfaces';
import {
  BoundListingDeleteErrorComponent,
} from '@ecommerce/components/plugins/bound-listing-delete-error/bound-listing-delete-error.component';

@Injectable()
export class GlobalMessageService extends GlobalMessageCommon {

  boundListingDeleteError(message: StringObjectDTO): void {
    this.snackBar.openFromComponent<BoundListingDeleteErrorComponent, StringObjectDTO>(BoundListingDeleteErrorComponent, {
      duration: 30000,
      panelClass: [MaterialCustomClasses.ErrorBar],
      data: message,
    });
  }

  override handleCustomError(errorResponse: any): boolean {
    if (errorResponse.length > 0 && errorResponse[1] === ApiCommonResponseMessages.cantDeleteSelectedItems) {
      this.boundListingDeleteError(JSONParse(errorResponse[0], [])[0]);
      return true;
    } else if (errorResponse[0][ApiCommonResponseMessages.cantCullBoundListings] ||
      errorResponse[0][ApiCommonResponseMessages.cantArchiveBoundListings] ||
      errorResponse[0][ApiCommonResponseMessages.cantUnarchiveBoundListings]) {
      this.boundListingDeleteError(errorResponse[0]);
      return true;
    }
    return false;
  }

}
