@if (errorType) {
  <div class="snackMessage">
    <b [innerHTML]="boundListingDeleteErrorTexts.title"></b>
    <p>
      @switch (errorType) {
        @case (errorTypes.cantDeleteBoundListings) {
          {{ boundListingDeleteErrorTexts.cantDeleteBoundListing }}
        }
        @case (errorTypes.cantCullBoundListings) {
          {{ boundListingDeleteErrorTexts.cantCullBoundListing }}
        }
        @case (errorTypes.cantArchiveBoundListings) {
          {{ boundListingDeleteErrorTexts.cantArchiveBoundListing }}
        }
        @case (errorTypes.cantUnarchiveBoundListings) {
          {{ boundListingDeleteErrorTexts.cantUnarchiveBoundListing }}
        }
      }
      <span [innerHTML]="boundListingDeleteErrorTexts.clickHere" (click)="showListings()"></span>
    </p>
  </div>
}
<app-hero-icon (click)="boundListingDeleteErrorComponentRef.dismiss()" name="x-circle" class="closeIcon" />
