export enum FieldTypes {
  Text = 1,
  Number = 2,
  Date = 3,
  List = 4,
  Boolean = 5,
  Multiline = 6,
  Options = 7,
  Time = 8,
}
