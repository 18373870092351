import {MarketPlaces} from '@common/enum/marketPlaces.enum';
import {LoadFrom} from '@common/ts/interfaces';

export function getMarketplaceName(value: MarketPlaces): LoadFrom {
  switch (value) {
    case MarketPlaces.Amazon:
      return 'amazon';
    case MarketPlaces.Ebay:
      return 'ebay';
    case MarketPlaces.Etsy:
      return 'etsy';
    case MarketPlaces.Shopiago:
      return 'shopiago';
    case MarketPlaces.Shopify:
      return 'shopify';
  }
}
