import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import texts from '@common/json/confirm';
import {FromEventCommon} from '@common/ts/fromEventCommon';
import {ConfirmDialogDTO} from '@common/ts/interfaces';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.scss',
  standalone: true,
  imports: [
    MatButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmComponent extends FromEventCommon {

  public confirmTexts = texts;
  public data = inject<ConfirmDialogDTO>(MAT_DIALOG_DATA);

  constructor(public dialogRef: MatDialogRef<ConfirmComponent, boolean>) {
    super();
  }

  override handleEnterKeydown(event: Observable<KeyboardEvent>): void {
    event.pipe(untilDestroyed(this)).subscribe(() => this.dialogRef.close(true));
  }

}
