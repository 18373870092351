import {Routes} from '@angular/router';
import {LOGIN_REDIRECT, NOT_FOUND_REDIRECT} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...LOGIN_REDIRECT,
  {
    path: 'login',
    loadChildren: () => import('../landing-pages/login/login.routes'),
  },
  {
    path: 'impersonate/:id',
    loadChildren: () => import('../landing-pages/impersonate-login/impersonate-login.routes'),
  },
  {
    path: 'activate/:id',
    loadChildren: () => import('../landing-pages/activate/activate.routes'),
  },
  {
    path: 'unsubscribe/reminder/:id',
    loadChildren: () => import('../landing-pages/unsubscribe/unsubscribe.routes'),
  },
  {
    path: 'ebaylinkaccount',
    loadChildren: () => import('../landing-pages/activate-ebay/activate-ebay.routes'),
  },
  {
    path: 'etsyactivate',
    loadChildren: () => import('../landing-pages/etsy-account-link/etsy-account-link.routes'),
  },
  {
    path: 'shopifyactivate',
    loadChildren: () => import('../landing-pages/shopify-account-link/shopify-account-link.routes'),
  },
  {
    path: 'registration',
    loadChildren: () => import('../landing-pages/registration/registration.routes'),
  },
  {
    path: 'registrationsuccess',
    loadChildren: () => import('../landing-pages/registration-success/registration-success.routes'),
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('../landing-pages/forgot-password/forgot-password.routes'),
  },
  {
    path: 'resetpassword/:id',
    loadChildren: () => import('../landing-pages/password-reset/password-reset.routes'),
  },
  {
    path: 'rollbackpassword/:id',
    loadChildren: () => import('../landing-pages/rollback-password/rollback-password.routes'),
  },
  {
    path: 'printservicetest',
    loadChildren: () => import('../landing-pages/print-service/print-service.routes'),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../main-pages/dashboard/dashboard.routes'),
  },
  {
    path: 'inventory',
    loadChildren: () => import('../main-pages/inventory-list/inventory-list.routes'),
  },
  {
    path: 'inventory/item/details/:id',
    loadChildren: () => import('../main-pages/inventory-detail/inventory-detail.routes'),
  },
  {
    path: 'inventory/item/:event',
    loadChildren: () => import('../main-pages/inventory-item/inventory-item.routes'),
  },
  {
    path: 'customers',
    loadChildren: () => import('../main-pages/client-history/client-history.routes'),
  },
  {
    path: 'customers/details/:id',
    loadChildren: () => import('../main-pages/client-detail/client-detail.routes'),
  },
  {
    path: 'orders',
    loadChildren: () => import('../main-pages/orders/orders.routes'),
  },
  {
    path: 'shipments',
    loadChildren: () => import('../main-pages/shipments/shipments.routes'),
  },
  {
    path: 'shipment/details/:id',
    loadChildren: () => import('../main-pages/shipment-detail/shipment-detail.routes'),
  },
  {
    path: 'orders/details/:id',
    loadChildren: () => import('../main-pages/order-detail/order-detail.routes'),
  },
  {
    path: 'listings',
    loadChildren: () => import('../main-pages/listing-list/listing-list.routes'),
  },
  {
    path: ':type/details/:id',
    loadChildren: () => import('../main-pages/listing-detail/listing-detail.routes'),
  },
  {
    path: 'listing/:event',
    loadChildren: () => import('../main-pages/listing-create/listing-create.routes'),
  },
  {
    path: 'images',
    loadChildren: () => import('../main-pages/image-gallery/image-gallery.routes'),
  },
  {
    path: 'ebaymessages',
    loadChildren: () => import('../main-pages/ebay-message-inbox/ebay-message-inbox.routes'),
  },
  {
    path: 'reports/custom',
    loadChildren: () => import('../main-pages/custom-report/custom-report.routes'),
  },
  {
    path: 'reports/:reportName',
    loadChildren: () => import('../main-pages/reports/reports.routes'),
  },
  {
    path: 'settings/myaccount',
    loadChildren: () => import('../main-pages/account-settings/account-settings.routes'),
  },
  {
    path: 'settings/myprofile',
    loadChildren: () => import('../main-pages/profile-settings/profile-settings.routes'),
  },
  {
    path: 'settings/manageaccounts',
    loadChildren: () => import('../main-pages/account-list/account-list.routes'),
  },
  {
    path: 'settings/emailpreferences',
    loadChildren: () => import('../main-pages/email-preferences/email-preferences.routes'),
  },
  {
    path: 'settings/bankaccount',
    loadChildren: () => import('../main-pages/bank-account/bank-account.routes'),
  },
  {
    path: 'settings/ebaypolicies',
    loadChildren: () => import('../main-pages/ebay-policies/ebay-policies.routes'),
  },
  {
    path: 'settings/flatrates',
    loadChildren: () => import('../main-pages/flat-rates/flat-rates.routes'),
  },
  {
    path: 'settings/users',
    loadChildren: () => import('../main-pages/users/users.routes'),
  },
  {
    path: 'settings/users/detail',
    loadChildren: () => import('../main-pages/user-detail/user-detail.routes'),
  },
  {
    path: 'settings/myprofile/detail',
    loadChildren: () => import('../main-pages/user-detail/user-detail.routes'),
  },
  {
    path: 'settings/users/modify/:id',
    loadChildren: () => import('../main-pages/user-modify/user-modify.routes'),
  },
  {
    path: 'settings/teams',
    loadChildren: () => import('../main-pages/teams/teams.routes'),
  },
  {
    path: 'settings/teams/:event',
    loadChildren: () => import('../main-pages/team-create/team-create.routes'),
  },
  {
    path: 'settings/transfertotes',
    loadChildren: () => import('../main-pages/transfer-boxes/transfer-boxes.routes'),
  },
  {
    path: 'settings/locations',
    loadChildren: () => import('../main-pages/locations/locations.routes'),
  },
  {
    path: 'settings/locations/:event',
    loadChildren: () => import('../main-pages/location-create/location-create.routes'),
  },
  {
    path: 'settings/blockedkeywords',
    loadChildren: () => import('../main-pages/blocked-keyword/blocked-keyword.routes'),
  },
  {
    path: 'settings/shippingmethods',
    loadChildren: () => import('../main-pages/shipping-method/shipping-method.routes'),
  },
  {
    path: 'settings/shippingmethods/:marketplace',
    loadChildren: () => import('../main-pages/shipping-method-create/shipping-method-create.routes'),
  },
  {
    path: 'settings/inventoryattributes',
    loadChildren: () => import('../main-pages/inventory-settings/inventory-settings.routes'),
  },
  {
    path: 'settings/inventoryattributes/:event',
    loadChildren: () => import('../main-pages/inventory-settings-create/inventory-settings-create.routes'),
  },
  {
    path: 'settings/automatedjobs',
    loadChildren: () => import('../main-pages/automated-jobs/automated-jobs.routes'),
  },
  {
    path: 'settings/htmltemplates',
    loadChildren: () => import('../main-pages/listing-template-list/listing-template-list.routes'),
  },
  {
    path: 'settings/htmltemplates/:event',
    loadChildren: () => import('../main-pages/listing-template-create/listing-template-create.routes'),
  },
  {
    path: 'settings/messagetemplates',
    loadChildren: () => import('../main-pages/message-template-list/message-template-list.routes'),
  },
  {
    path: 'settings/messagetemplates/:event',
    loadChildren: () => import('../main-pages/message-template-create/message-template-create.routes'),
  },
  {
    path: 'settings/inventoryimport',
    loadChildren: () => import('../main-pages/inventory-import/inventory-import.routes'),
  },
  {
    path: 'settings/tokenrequests',
    loadChildren: () => import('../main-pages/token-requests/token-requests.routes'),
  },
  {
    path: 'settings/defaultvalues',
    loadChildren: () => import('../main-pages/default-values-list/default-values-list.routes'),
  },
  {
    path: 'settings/defaultvalues/:event',
    loadChildren: () => import('../main-pages/default-values-form/default-values-form.routes'),
  },
  {
    path: 'settings/repricerules',
    loadChildren: () => import('../main-pages/reprice-rules/reprice-rules.routes'),
  },
  {
    path: 'settings/repricerule/:event',
    loadChildren: () => import('../main-pages/reprice-rule-create/reprice-rule-create.routes'),
  },
  {
    path: 'settings/giftaidrule/:event',
    loadChildren: () => import('../main-pages/gift-aid-rule-create/gift-aid-rule-create.routes'),
  },
  {
    path: 'notifications',
    loadChildren: () => import('../main-pages/notifications/notifications.routes'),
  },
  ...NOT_FOUND_REDIRECT,
];
