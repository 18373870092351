@if (uploading()) {
  <section class="snackMessage">
    <b>{{ imageUploadProgressBarTexts.title }}</b>
    <div class="progress">
      <div
        [style.width.%]="uploadPercent()"
        aria-valuemax="100"
        aria-valuemin="0"
        aria-valuenow="exportProcessed"
        class="progress-bar"
        role="progressbar"></div>
      <span class="progressLabel">{{ uploadLabel() }}</span>
    </div>
  </section>
} @else {
  @if (errors.length) {
    <section class="snackErrorMessage">
      <b>{{ data.files.length + '/' + validImages.length }}{{ imageUploadProgressBarTexts.errorTitle }}</b>
      @for (error of errors; track $index) {
        <label>- {{ error }}</label>
      }
    </section>
  }
  <app-hero-icon (click)="uploadProgressRef.dismiss()" name="x-circle" class="closeIcon" />
}
