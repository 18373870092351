import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {Roles} from '@common/enum/roles.enum';
import {ACCOUNT_SETTINGS_ROLES} from '@common/globals/accountSettingsRoles';
import {ADMIN_ROLES} from '@common/globals/adminRoles';
import {BLOCKED_KEYWORD_ROLES} from '@common/globals/blockedKeywordRoles';
import {BULK_IMPORT_ROLES} from '@common/globals/bulkImportRoles';
import {ONLY_SETTINGS_ROLES} from '@common/globals/onlySettingsRoles';
import {PROFILE_SETTINGS_ROLES} from '@common/globals/profileSettingsRoles';
import {USER_ROLES} from '@common/globals/userRoles';
import texts from '@common/json/ecommerceMainMenu';
import {AppModules} from '@common/statics/modules';
import {UserRoles} from '@common/statics/userRoles';
import {CountryCodes, MenuOverlayGroupData, MenuOverlayOptions, MenuOverlaySubOptions, Urls} from '@common/ts/interfaces';

const tradeInSettingsGroup = [
    texts.accountSettings,
    texts.emailPreferences,
    texts.profileSettings,
    texts.users,
  ],
  shopiagoGroup: MenuOverlayGroupData<Urls>[] = [{
    name: texts.accountSettings,
    url: 'settings/myaccount',
    visibleForRoles: ACCOUNT_SETTINGS_ROLES,
  }, {
    name: texts.bankAccount,
    url: 'settings/bankaccount',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.profileSettings,
    url: 'settings/myprofile',
    visibleForRoles: PROFILE_SETTINGS_ROLES,
  }, {
    name: texts.teams,
    url: 'settings/teams',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.users,
    url: 'settings/users',
    visibleForRoles: USER_ROLES,
  }, {
    name: texts.jobScheduling,
    url: 'settings/automatedjobs',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.tokenRequests,
    url: 'settings/tokenrequests',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.emailPreferences,
    url: 'settings/emailpreferences',
    visibleForRoles: ADMIN_ROLES,
  }],

  marketplaceGroup: MenuOverlayGroupData<Urls>[] = [{
    name: texts.marketplaceAccounts,
    url: 'settings/manageaccounts',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.ebayPolicies,
    url: 'settings/ebaypolicies',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.flatRates,
    url: 'settings/flatrates',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.messageTemplates,
    url: 'settings/messagetemplates',
    visibleForRoles: ADMIN_ROLES,
  }],

  listingGroup: MenuOverlayGroupData<Urls>[] = [{
    name: texts.blockedKeywords,
    url: 'settings/blockedkeywords',
    visibleForRoles: BLOCKED_KEYWORD_ROLES,
  }, {
    name: texts.htmlTemplates,
    url: 'settings/htmltemplates',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.shippingMethods,
    url: 'settings/shippingmethods',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.defaultValues,
    url: 'settings/defaultvalues',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.repriceRules,
    url: 'settings/repricerules',
    visibleForRoles: ADMIN_ROLES,
  }],

  productGroup: MenuOverlayGroupData<Urls>[] = [{
    name: texts.locations,
    url: 'settings/locations',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.transferBoxes,
    url: 'settings/transfertotes',
    visibleForRoles: ADMIN_ROLES,
  }, {
    name: texts.productSettings,
    url: 'settings/inventoryattributes',
    visibleForRoles: [Roles.ROLE_ACCOUNT_OWNER],
  }, {
    name: texts.bulkProductImport,
    url: 'settings/inventoryimport',
    visibleForRoles: BULK_IMPORT_ROLES,
  }];

function getProductGroupOptions(settingsOnly: boolean, inventoryImportEnabled: boolean): MenuOverlayOptions<Urls>[] {
  return productGroup.flatMap(({name, visibleForRoles, url}) => {
    if (UserRoles.hasRole(visibleForRoles) && (!settingsOnly || settingsOnly && name === texts.locations) &&
      (name === texts.bulkProductImport && inventoryImportEnabled || name !== texts.bulkProductImport)) {
      return [{name, url}];
    }
    return [];
  });
}

function getListingGroupOptions(settingsOnly: boolean): MenuOverlayOptions<Urls>[] {
  if (!settingsOnly) {
    return listingGroup.flatMap(({name, visibleForRoles, url}) => {
      if (UserRoles.hasRole(visibleForRoles)) {
        return [{name, url}];
      }
      return [];
    });
  }
  return [];
}

function getMarketplaceGroupOptions(settingsOnly: boolean): MenuOverlayOptions<Urls>[] {
  if (!settingsOnly) {
    return marketplaceGroup.flatMap(({name, visibleForRoles, url}) => {
      if (UserRoles.hasRole(visibleForRoles)) {
        return [{name, url}];
      }
      return [];
    });
  }
  return [];
}

function allowBankAccount(name: string, country: CountryCodes): boolean {
  return name === texts.bankAccount && (country === 'GB' || country === 'IE');
}

function shopiagoSettingsValidation(name: string, settingsOnly: boolean, country: CountryCodes): boolean {
  if (!settingsOnly) {
    if (name !== texts.bankAccount && name !== texts.emailPreferences) {
      return true;
    }
    return allowBankAccount(name, country) || name !== texts.bankAccount;
  }
  if (tradeInSettingsGroup.includes(name)) {
    return name === texts.emailPreferences && AppModules.hasModule(ModuleTypes.TradeIn) || name !== texts.emailPreferences;
  }
  return allowBankAccount(name, country);
}

function getShopiagoGroupOptions(settingsOnly: boolean, country: CountryCodes): MenuOverlayOptions<Urls>[] {
  return shopiagoGroup.flatMap(({name, visibleForRoles, url}) => {
    if (UserRoles.hasRole(visibleForRoles) && shopiagoSettingsValidation(name, settingsOnly, country)) {
      return [{name, url}];
    }
    return [];
  });
}

export function getSettingsSubOptions(country: CountryCodes, inventoryImportEnabled: boolean): MenuOverlaySubOptions<Urls>[] {
  const settingsOnly = !AppModules.hasModule(ModuleTypes.Ecommerce) && UserRoles.hasRole(ONLY_SETTINGS_ROLES);
  return [{
    title: texts.shopiagoGroup,
    options: getShopiagoGroupOptions(settingsOnly, country),
  }, {
    title: texts.marketplaceGroup,
    options: getMarketplaceGroupOptions(settingsOnly),
  }, {
    title: texts.listingGroup,
    options: getListingGroupOptions(settingsOnly),
  }, {
    title: texts.productGroup,
    options: getProductGroupOptions(settingsOnly, inventoryImportEnabled),
  }];
}
