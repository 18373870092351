import {Injectable} from '@angular/core';
import {LocalStorageModuleTypes} from '@common/enum/localStorageModuleTypes';
import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {Roles} from '@common/enum/roles.enum';
import {ONLY_SETTINGS_PAGES} from '@common/globals/ecommerceOnlySettingsPages';
import {EnvUrl} from '@common/statics/getEnvURL';
import {getLoginData} from '@common/statics/getLoginData';
import {AppModules} from '@common/statics/modules';
import {UserRoles} from '@common/statics/userRoles';
import {AuthCommon} from '@common/ts/authCommon';
import {Urls} from '@common/ts/interfaces';
import {MenuRoutesService} from '@ecommerce/services/menu-routes/menu-routes.service';
import {UserDataService} from '@ecommerce/services/user-data/user-data.service';
import {Observable, of, switchMap} from 'rxjs';

@Injectable()
export class AuthService extends AuthCommon<Urls, Roles> {

  constructor(public routerSrv: MenuRoutesService,
              public override userDataService: UserDataService) {
    super(userDataService);
  }

  override authHandler(roles: Roles[]): Observable<boolean> {
    const onlySettings = !AppModules.hasModule(ModuleTypes.Ecommerce) && this.userDataService.currentUrl.includes('/settings') || AppModules.hasAdminModule(ModuleTypes.Ecommerce);
    if (AppModules.hasModule(ModuleTypes.Ecommerce) || onlySettings) {
      return this.handleEcommerceData(roles, onlySettings);
    } else if (AppModules.hasModule(ModuleTypes.TradeIn)) {
      return this.goToModule('TradeIn');
    } else if (AppModules.hasModule(ModuleTypes.Collection)) {
      return this.goToModule('Collection');
    }
    return this.goToLogin();
  }

  private handleEcommerceData(roles: Roles[], onlySettings: boolean): Observable<boolean> {
    if (UserRoles.hasRole(roles) && (AppModules.hasModule(ModuleTypes.Ecommerce) || onlySettings &&
      this.isOnlySettingsPage())) {
      return this.userDataService.userDataCheck().pipe(switchMap((checked) => {
        if (checked && this.hasSpecificRoles()) {
          this.userDataService.httpUpdate.next(false);
          if (!getLoginData().isImpersonate) {
            return this.userDataService.localSSrv.saveLocalStorageData(LocalStorageModuleTypes.Ecommerce).pipe(
              switchMap(() => of(true)));
          }
          return of(true);
        }
        return of(false);
      }));
    }
    this.routerSrv.dashboardRoute();
    return of(false);
  }

  goToModule(type: keyof typeof LocalStorageModuleTypes): Observable<boolean> {
    window.location.assign(EnvUrl.getEnvURL(type));
    return of(false);
  }

  goToLogin(): Observable<boolean> {
    this.routerSrv.menuRoutes('login');
    return of(false);
  }

  isOnlySettingsPage(): boolean {
    return ONLY_SETTINGS_PAGES.some((page) => this.userDataService.currentUrl.includes(`/${page}`));
  }

  hasSpecificRoles(): boolean {
    const {currentUrl, userData} = this.userDataService;
    return currentUrl.includes('/bankaccount') ?
      userData.country === 'GB' || userData.country === 'IE' : true;
  }

  removeData(): void {
    this.userDataService.dataRemover();
    this.routerSrv.menuRoutes('login');
  }

}
