export class GridResponse<T, S = any, G = any> {

  data = <T[]>[];
  aggregationExtraData?: Record<string, {
    aggrType: string;
    data: number | string | boolean;
  }>[];

  pageNumber = 0;
  pageSize = 0;
  summary?: S;
  groupedSummary?: G;
  totalElements = 0;

}
