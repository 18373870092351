import {Injectable} from '@angular/core';
import {FilterGroupDTO} from '@common/ts/interfaces';

@Injectable()
export class AutoFilterService<S, E extends string = null> {

  private autoFilterData: Partial<Record<keyof S, FilterGroupDTO<any, any, E>[]>> = {};

  setFilters<G extends(keyof S)>(gridNames: G[], filters: FilterGroupDTO<S[G], S[G], E>[]): void {
    for (const gridName of gridNames) {
      this.autoFilterData[gridName] = filters;
    }
  }

  haveFilter<G extends(keyof S)>(gridName: G): boolean {
    return Boolean(this.autoFilterData[gridName]);
  }

  getFilters<G extends(keyof S), R>(filters: FilterGroupDTO<S[G], R, E>[], gridNames: G[]): FilterGroupDTO<S[G], R, E>[] {
    for (const gridName of gridNames) {
      if (this.haveFilter(gridName)) {
        for (const filter of this.autoFilterData[gridName]) {
          const filterIndex = filters.findIndex(({prop}) => prop === filter.prop);
          if (filterIndex !== -1) {
            filters[filterIndex] = filter;
          } else {
            filters.push(filter);
          }
        }
        this.autoFilterData[gridName] = undefined;
      }
    }
    return filters;
  }

}
