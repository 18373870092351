import {EventEmitter, Injectable} from '@angular/core';
import {getSettingsSubOptions} from '@common/globals/ecommerceSettings';
import {FinalFormGroup} from '@common/statics/formHelper';
import {EnvUrl} from '@common/statics/getEnvURL';
import {MarketplaceSearchForm} from '@common/ts/formInterfaces';
import {
  ExternalSearchEvent,
  ListingDialogDTO,
  MarketplaceSearchDetailDTO,
  MarketplaceSearchDTO,
  MenuOverlaySubOptions,
  OrderDialogDTO,
  ProductDialogDTO,
  StorageData,
  Urls,
} from '@common/ts/interfaces';
import {UserDataCommon} from '@common/ts/userDataCommon';

@Injectable()
export class UserDataService extends UserDataCommon<Urls, StorageData> {

  public searchResultNumber = 0;
  public searchText = '';
  public lastFilterId = '';
  public extendedSearch = false;
  public searchData: FinalFormGroup<MarketplaceSearchForm> = null;
  public searchResult: MarketplaceSearchDTO = null;
  public previousModuleURL: string = null;
  public highlightId: string = null;
  public notificationCount: number = null;
  public marketplaceDetail: MarketplaceSearchDetailDTO = null;
  public externalSearch = new EventEmitter<ExternalSearchEvent>();
  public listingDetailDialog = new EventEmitter<ListingDialogDTO>();
  public productDetailDialog = new EventEmitter<ProductDialogDTO>();
  public orderDetailDialog = new EventEmitter<OrderDialogDTO>();
  public autoFilterChange = new EventEmitter<boolean>();
  public accountListRefresh = new EventEmitter<boolean>();
  public photoRecognition = new EventEmitter<boolean>();
  public startSell = new EventEmitter<boolean>();
  public filterDataCountRefresh = new EventEmitter<boolean>();

  getSettingsSubOptions(): MenuOverlaySubOptions<Urls>[] {
    const {country, enabledFeatureToggles} = this.userData;
    return getSettingsSubOptions(country, enabledFeatureToggles.inventoryImportEnabled);
  }

  dataRemover(): void {
    this.mainDataRemover();
    this.highlightId = null;
    this.previousModuleURL = EnvUrl.getEnvURL('Ecommerce');
    this.searchResultNumber = 0;
    this.notificationCount = null;
    this.extendedSearch = false;
    this.searchText = '';
    this.lastFilterId = '';
    this.searchData = null;
    this.searchResult = null;
    this.marketplaceDetail = null;
  }

}
