/* istanbul ignore file */

import {MatDialogConfig} from '@angular/material/dialog';

export const MAX_FILE_SIZE = 15485760;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_MIN_LENGTH = 4;

export const EBAY_TITLE_MAX_LENGTH = 80;

export const ETSY_TITLE_MAX_LENGTH = 140;

export const SHOPIFY_TITLE_MAX_LENGTH = 255;

export const OPTION_SELECTION_LIMIT = 100;

export const MAX_IMAGE_NUMBER = 24;

export const MAX_IMAGE_STORE_NUMBER = 36;

export const DESCRIPTION_MIN_LENGTH = 20;

export const MINUTE = 60000;

export const HALF_MINUTE = 30000;

export const QUARTER_MINUTE = 15000;

export const WAITING_TIME = 500;

export const SEARCH_START_LENGTH = 2;

export const GRID_DEFAULT_CONFIG: MatDialogConfig = {
  width: '600px',
};

export const GRID_DIALOG_CONFIG: MatDialogConfig = {
  width: '800px',
};

export const FULL_DIALOG_CONFIG: MatDialogConfig = {
  width: '70%',
};

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const WITHOUT_CHANGE = {emitEvent: false};

export const MINIMUM_PRICE = {
  Auction: 0.01,
  Fixed: 0.99,
};
