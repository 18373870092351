<div class="stepHeader">
  <h1>{{ data.customTitle ? data.customTitle : confirmTexts.title }}</h1>
</div>
<span [innerHTML]="data.value" class="dialogContent mb-3"></span>
<div class="d-flex justify-content-center mb-2">
  <button (click)="dialogRef.close(true)" class="mb-2 me-2 saveBtn" mat-button>
    {{ data.yesOrNo ? confirmTexts.yes : confirmTexts.confirm }}
  </button>
  <button (click)="dialogRef.close(false)" class="mb-2 ms-2 cancelBtn" mat-button>
    {{ data.yesOrNo ? confirmTexts.no : confirmTexts.cancel }}
  </button>
</div>
