import {isLocalhost} from '@common/statics/isLocalhost';

interface TagManagerEvent {
  event: 'page_view';
}

export function sendEvent(event: TagManagerEvent): void {
  if (event && !isLocalhost()) {
    window.dataLayer.push(event);
  }
}
