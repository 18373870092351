import {Injectable} from '@angular/core';
import {GridResponse} from '@common/globals/gridResponse';
import {addPrefix, FilterGroupDTO, GridFilterDTO, GridSortDTO, LastGridSearchDTO, MenuPages, Urls} from '@common/ts/interfaces';

@Injectable()
export class GridSearchService<G = MenuPages, U = Urls> {

  private _lastGridSearch: LastGridSearchDTO<G, U>;

  clearData(): void {
    this._lastGridSearch = null;
  }

  get lastGridSearch(): LastGridSearchDTO<G, U> {
    return this._lastGridSearch;
  }

  gridCheck(gridName: G, currentUrl: addPrefix<U, '/'>): boolean {
    return this._lastGridSearch?.gridName === gridName && this._lastGridSearch?.url === currentUrl;
  }

  idCheck(id: string): boolean {
    return this._lastGridSearch ? this._lastGridSearch.savedFilter?.id === id : false;
  }

  removeGridData(): void {
    if (this._lastGridSearch) {
      this._lastGridSearch.indeterminate = false;
      this._lastGridSearch.selectedAll = false;
      this._lastGridSearch.data = new GridResponse<any>();
      this._lastGridSearch.selected = [];
    }
  }

  setGridData(data: GridResponse<any>, filter: FilterGroupDTO<any>[], gridName: G, indeterminate: boolean,
              selectedPageSize: number, savedFilter: GridFilterDTO<any>, scrollTop: number, selected: any[],
              selectedAll: boolean, sorts: GridSortDTO<any>[], url: addPrefix<U, '/'>): void {
    this._lastGridSearch = {
      data,
      filter,
      gridName,
      indeterminate,
      selectedPageSize,
      savedFilter,
      selected,
      sorts,
      selectedAll,
      scrollTop,
      url,
    };
  }

}
