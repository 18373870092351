import {ChangeDetectionStrategy, Component} from '@angular/core';
import {HeroIconComponent} from '@common/components/hero-icon/hero-icon.component';

import texts from './alertNotification';

@Component({
  selector: 'app-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrl: './alert-notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HeroIconComponent,
  ],
})
export class AlertNotificationComponent {

  public notificationText = texts;

}
