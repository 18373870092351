import {Injectable} from '@angular/core';
import {MarketPlaces} from '@common/enum/marketPlaces.enum';
import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {Roles} from '@common/enum/roles.enum';
import {ASSISTANT_USER, SYSTEM_USER} from '@common/globals/systemUser';
import {USER_ROLES} from '@common/globals/userRoles';
import {getLoginData} from '@common/statics/getLoginData';
import {getMarketplaceName} from '@common/statics/marketplaceName';
import {AppModules} from '@common/statics/modules';
import {UserRoles} from '@common/statics/userRoles';
import {LoadFrom, Urls, UserEvent} from '@common/ts/interfaces';
import {RouteCommon} from '@common/ts/routeCommon';

@Injectable()
export class MenuRoutesService extends RouteCommon<Urls> {

  // eslint-disable-next-line sonar/cyclomatic-complexity
  listingRoutes(userEvent: UserEvent, from: LoadFrom = null, source: 'listing' | 'revise' = null, id: string = null,
                marketplace: LoadFrom = null, site: string = null, condition: string = null, account: MarketPlaces = null): void {
    switch (userEvent) {
      case 'details':
        this.menuRoutes(`${source}/${userEvent}/${id}` as Urls);
        break;
      case 'create':
        this.menuRoutes('listing/create');
        break;
      case 'modify':
      case 'approve':
      case 'relist':
      case 'revise':
      case 'from':
      case 'newfrom':
        this.menuRoutes(`${source}/${userEvent}/${from}/${id}${this.getMarketplaceName(account)}` as Urls);
        break;
      case 'search':
        this.menuRoutes(`${source}/create/${marketplace}/${from}/${site}/${condition}/${id}` as Urls);
        break;
    }
  }

  private getMarketplaceName(account: MarketPlaces): string {
    return account !== null ? `/to/${getMarketplaceName(account)}` : '';
  }

  productRoutes(userEvent: UserEvent, id: string, from = 'search', marketplace: LoadFrom = null, site: string = null,
                condition: string = null): void {
    switch (userEvent) {
      case 'create':
        this.menuRoutes('inventory/item', userEvent);
        break;
      case 'details':
      case 'modify':
        this.menuRoutes('inventory/item', `${userEvent}/${id}`);
        break;
      case 'from':
        this.menuRoutes('inventory/item/create', `${userEvent}/item/${id}`);
        break;
      case 'search':
        this.menuRoutes('inventory/item/create', `${marketplace}/${from}/${site}/${condition}/${id}`);
        break;
    }
  }

  userRoutes(userEvent: 'details' | 'modify', userId: string, myUserId: string): void {
    if (userId !== SYSTEM_USER && userId !== ASSISTANT_USER) {
      if (userEvent === 'details' && myUserId !== null) {
        this.userDetailRoute(userId, myUserId);
      } else if (userEvent === 'modify' && myUserId !== null) {
        this.menuRoutes(userId === myUserId ? 'settings/myprofile' : 'settings/users/modify',
          userId === myUserId ? null : userId);
      }
    }
  }

  private userDetailRoute(userId: string, myUserId: string): void {
    if (userId === myUserId) {
      this.menuRoutes('settings/myprofile/detail');
    } else if (UserRoles.hasRole(USER_ROLES)) {
      this.menuRoutes('settings/users/detail', userId);
    }
  }

  dashboardRoute(fromActivate = false): void {
    const {roles} = getLoginData();
    if (!AppModules.hasModule(ModuleTypes.Ecommerce) && AppModules.hasAdminModule(ModuleTypes.Ecommerce)) {
      this.menuRoutes(fromActivate ? 'settings/myaccount' : 'settings/myprofile');
    } else if (UserRoles.hasRole([Roles.ROLE_DISABLED])) {
      this.menuRoutes('settings/myaccount');
    } else if (UserRoles.hasRole([Roles.ROLE_AREA_MANAGER])) {
      this.menuRoutes('reports', 'custom');
    } else if (roles.length === 2 && roles.includes(Roles.ROLE_MESSENGER)) {
      this.menuRoutes('ebaymessages', 'System');
    } else {
      this.menuRoutes('dashboard');
    }
  }

}
