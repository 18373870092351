import {Roles} from '@common/enum/roles.enum';

export const ONLY_SETTINGS_ROLES = [
  Roles.ROLE_ACCOUNT_OWNER,
  Roles.ROLE_ACCOUNT_ADMIN,
  Roles.ROLE_SHOP_MANAGER,
  Roles.ROLE_SHOP_ASSISTANT,
  Roles.ROLE_SHOP_VOLUNTEER,
  Roles.ROLE_AREA_MANAGER,
  Roles.ROLE_SHOP_LISTER,
  Roles.ROLE_TRADE_INDICATE_PRICE,
];
