/* istanbul ignore file */

import {APP_BASE_HREF, DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {enableProdMode, ErrorHandler, importProvidersFrom, Injectable, provideZoneChangeDetection} from '@angular/core';
import {MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/autocomplete';
import {MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/datepicker';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/menu';
import {MAT_SELECT_CONFIG, MAT_SELECT_SCROLL_STRATEGY_PROVIDER} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DateFnsAdapter, MAT_DATE_FNS_FORMATS} from '@angular/material-date-fns-adapter';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, TitleStrategy} from '@angular/router';
import {DATE_PIPE_CONFIG} from '@common/globals/datePipeConfig';
import {MAT_AUTOCOMPLETE_CONFIG} from '@common/globals/matAutocompleteConfig';
import {MAT_CHIP_CONFIG} from '@common/globals/matChipConfig';
import {MAT_DIALOG_CONFIG} from '@common/globals/matDialogConfig';
import {MAT_FORM_FIELD_CONFIG} from '@common/globals/matFormFieldConfig';
import {MAT_RIPPLE_CONFIG} from '@common/globals/matRippleConfig';
import {MAT_SELECT_DEFAULT_CONFIG} from '@common/globals/matSelectConfig';
import {NG_SCROLLBAR_CONFIG} from '@common/globals/ngScrollbarConfig';
import {SentryErrorHandler} from '@common/globals/sentryErrorHandler';
import appVersion from '@common/json/appVersion';
import {AutoFilterService} from '@common/services/auto-filter/auto-filter.service';
import {CanDeactivateService} from '@common/services/can-deactivate/can-deactivate.service';
import {CommonService} from '@common/services/common-service/common.service';
import {ConfirmService} from '@common/services/confirm-service/confirm.service';
import {DynamicScriptLoaderService} from '@common/services/dynamic-script-loader/dynamic-script-loader.service';
import {GridSearchService} from '@common/services/grid-search/grid-search.service';
import {ImageUploadService} from '@common/services/image-upload/image-upload.service';
import {LocalStorageService} from '@common/services/local-storage/local-storage.service';
import {VersionCheckService} from '@common/services/version-check/version-check.service';
import {initSentry} from '@common/statics/initSentry';
import {linkedinInsight} from '@common/statics/linkedinInsight';
import {GlobalMessageCommon} from '@common/ts/globalMessageCommon';
import {
  ADJUSTMENTS,
  ARROW_CIRCLE_RIGHT,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROWS_EXPAND,
  BELL,
  CALENDAR,
  CAMERA,
  CHART_BAR,
  CHART_PIE,
  CHAT_ALT,
  CHECK,
  CHECK_CIRCLE,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  CHEVRON_UP,
  CLIPBOARD_LIST,
  CLOCK,
  COG,
  COLLECTION,
  CREDIT_CARD,
  CROP,
  CUBE,
  CUBE_SOLID,
  CURRENCY_POUND,
  DATABASE,
  DOCUMENT_ADD,
  DOCUMENT_TEXT,
  DOTS_HORIZONTAL,
  DOTS_VERTICAL,
  DUPLICATE,
  EXCLAMATION,
  EYE,
  EYE_SLASH,
  FILTER,
  FLIP,
  FOLDER,
  FOLDER_ADD,
  GRAPH_DOWN,
  HASHTAG,
  INBOX,
  INFORMATION_CIRCLE,
  KEYFRAMES,
  LINK,
  LOCATION_MARKER,
  LOCK_CLOSED,
  LOCK_OPEN,
  MAIL,
  MAP,
  MENU,
  MINUS,
  MINUS_CIRCLE,
  PAPER_AIRPLANE,
  PAPER_CLIP,
  PENCIL,
  PENCIL_ALT,
  PHOTOGRAPH,
  PLAY,
  PLUS,
  PLUS_CIRCLE,
  PRESENTATION_CHART_LINE,
  PRINTER,
  QRCODE,
  QUESTION_MARK_CIRCLE,
  RECEIPT_TAX,
  REFRESH,
  REPLY,
  RESTART,
  ROTATE_CAMERA_LEFT,
  ROTATE_CAMERA_RIGHT,
  SAVE,
  SEARCH,
  SHOPPING_CART,
  STAR,
  STAR_SOLID,
  STOP,
  SUN,
  SUN_SOLID,
  SWITCH_VERTICAL,
  TABLE_2_COLUMNS,
  TAG,
  TRASH,
  TRENDING_UP,
  TRUCK,
  USER,
  USER_ADD,
  USERS,
  VIEW_BOARDS,
  VIEW_GRID,
  X,
  X_CIRCLE,
  X_CIRCLE_SOLID,
} from '@common/ts/heroIcons';
import {HI_ICON_SET_TOKEN} from '@common/ts/heroIconTokens';
import {PageTitleCommon} from '@common/ts/pageTitleCommon';
import {RouteCommon} from '@common/ts/routeCommon';
import {UserDataCommon} from '@common/ts/userDataCommon';
import {AppComponent} from '@ecommerce/components/app/app.component';
import {APP_ROUTES} from '@ecommerce/components/app/app.routes';
import {AuthService} from '@ecommerce/services/auth/auth.service';
import {GlobalMessageService} from '@ecommerce/services/global-message/global-message.service';
import {HttpInterceptorService} from '@ecommerce/services/http-interceptor/http-interceptor.service';
import {MenuRoutesService} from '@ecommerce/services/menu-routes/menu-routes.service';
import {UserDataService} from '@ecommerce/services/user-data/user-data.service';
import {enGB} from 'date-fns/locale';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NG_SCROLLBAR_OPTIONS} from 'ngx-scrollbar';

import {ENVIRONMENT} from './environments/environment';

if (ENVIRONMENT.production) {
  enableProdMode();
}

initSentry(ENVIRONMENT.sentryDns, appVersion.ecommerce);

if (ENVIRONMENT.linkedinPartnerId) {
  linkedinInsight(ENVIRONMENT.linkedinPartnerId);
}

@Injectable()
class TemplatePageTitleStrategy extends PageTitleCommon {

  constructor() {
    super('');
  }

}

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({
      eventCoalescing: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: MAT_DATE_LOCALE, useValue: enGB},
    {provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: MAT_AUTOCOMPLETE_CONFIG},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MAT_DIALOG_CONFIG},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: MAT_RIPPLE_CONFIG},
    {provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: MAT_CHIP_CONFIG},
    {provide: MAT_SELECT_CONFIG, useValue: MAT_SELECT_DEFAULT_CONFIG},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MAT_FORM_FIELD_CONFIG},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: DATE_PIPE_CONFIG},
    {provide: NG_SCROLLBAR_OPTIONS, useValue: NG_SCROLLBAR_CONFIG},
    {provide: UserDataCommon, useExisting: UserDataService},
    {provide: RouteCommon, useExisting: MenuRoutesService},
    {provide: GlobalMessageCommon, useExisting: GlobalMessageService, deps: [MatSnackBar]},
    {
      provide: HI_ICON_SET_TOKEN, useValue: {
        informationCircle: INFORMATION_CIRCLE,
        arrowLeft: ARROW_LEFT,
        arrowRight: ARROW_RIGHT,
        chevronLeft: CHEVRON_LEFT,
        chevronRight: CHEVRON_RIGHT,
        chevronDown: CHEVRON_DOWN,
        chevronUp: CHEVRON_UP,
        collection: COLLECTION,
        tag: TAG,
        truck: TRUCK,
        locationMarker: LOCATION_MARKER,
        trash: TRASH,
        filter: FILTER,
        x: X,
        minus: MINUS,
        check: CHECK,
        xCircleSolid: X_CIRCLE_SOLID,
        plus: PLUS,
        cog: COG,
        save: SAVE,
        switchVertical: SWITCH_VERTICAL,
        chartPie: CHART_PIE,
        chartBar: CHART_BAR,
        presentationChartLine: PRESENTATION_CHART_LINE,
        search: SEARCH,
        calendar: CALENDAR,
        xCircle: X_CIRCLE,
        viewGrid: VIEW_GRID,
        refresh: REFRESH,
        reply: REPLY,
        duplicate: DUPLICATE,
        plusCircle: PLUS_CIRCLE,
        minusCircle: MINUS_CIRCLE,
        currencyPound: CURRENCY_POUND,
        checkCircle: CHECK_CIRCLE,
        hashtag: HASHTAG,
        mail: MAIL,
        play: PLAY,
        pencil: PENCIL,
        inbox: INBOX,
        creditCard: CREDIT_CARD,
        user: USER,
        printer: PRINTER,
        clipboardList: CLIPBOARD_LIST,
        userAdd: USER_ADD,
        documentAdd: DOCUMENT_ADD,
        cube: CUBE,
        cubeSolid: CUBE_SOLID,
        users: USERS,
        receiptTax: RECEIPT_TAX,
        paperAirplane: PAPER_AIRPLANE,
        pencilAlt: PENCIL_ALT,
        star: STAR,
        starSolid: STAR_SOLID,
        folderAdd: FOLDER_ADD,
        database: DATABASE,
        camera: CAMERA,
        lockOpen: LOCK_OPEN,
        lockClosed: LOCK_CLOSED,
        folder: FOLDER,
        chatAlt: CHAT_ALT,
        menu: MENU,
        viewBoards: VIEW_BOARDS,
        exclamation: EXCLAMATION,
        bell: BELL,
        photograph: PHOTOGRAPH,
        questionMarkCircle: QUESTION_MARK_CIRCLE,
        link: LINK,
        paperClip: PAPER_CLIP,
        adjustments: ADJUSTMENTS,
        qrcode: QRCODE,
        eye: EYE,
        eyeSlash: EYE_SLASH,
        keyframes: KEYFRAMES,
        restart: RESTART,
        graphDown: GRAPH_DOWN,
        stop: STOP,
        table2columns: TABLE_2_COLUMNS,
        arrowCircleRight: ARROW_CIRCLE_RIGHT,
        flip: FLIP,
        rotateCameraLeft: ROTATE_CAMERA_LEFT,
        rotateCameraRight: ROTATE_CAMERA_RIGHT,
        sun: SUN,
        sunSolid: SUN_SOLID,
        crop: CROP,
        arrowsExpand: ARROWS_EXPAND,
        trendingUp: TRENDING_UP,
        dotsHorizontal: DOTS_HORIZONTAL,
        dotsVertical: DOTS_VERTICAL,
        shoppingCart: SHOPPING_CART,
        documentText: DOCUMENT_TEXT,
        clock: CLOCK,
        map: MAP,
      }, multi: true,
    },
    MenuRoutesService,
    AuthService,
    CommonService,
    UserDataService,
    VersionCheckService,
    GlobalMessageService,
    LocalStorageService,
    provideAnimations(),
    GridSearchService,
    ConfirmService,
    DynamicScriptLoaderService,
    CanDeactivateService,
    AutoFilterService,
    ImageUploadService,
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER,
    {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
    provideRouter(APP_ROUTES),
    importProvidersFrom(MatDialogModule),
    importProvidersFrom(NgxPageScrollCoreModule),
  ],
});
