export default {
  custom: 'Custom',
  userPerformance: 'User Performance',
  giftAid: 'Gift Aid',
  areaSales: 'Area Sales',
  shopSales: 'Shop Sales',
  userSales: 'User Sales',
  categorySales: 'Category Sales',
  teamReports: 'Team Reports',
  teamPerformance: 'Team Performance',
  teamProduct: 'Team Inventory',
  teamListing: 'Team Listing',
  teamSales: 'Team Sales',
  submissionReports: 'Submission Reports',
  shopSubmissionStatus: 'Shop Submission Status',
  rejectReason: 'Reject Reason',
};
