import {Urls} from '@common/ts/interfaces';

export const ONLY_SETTINGS_PAGES: Urls[] = [
  'settings/bankaccount',
  'settings/myprofile',
  'settings/users',
  'settings/emailpreferences',
  'settings/locations',
  'settings/myaccount',
];
