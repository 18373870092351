import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ImageUploadProgressBarComponent} from '@common/components/image-upload-progress-bar/image-upload-progress-bar.component';
import {MaterialCustomClasses} from '@common/enum/materialCustomClasses.enum';
import imageUpload from '@common/json/imageUpload';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {BACKEND_URL} from '@common/ts/config';
import {ImageDTO, ImageUploadBarDTO, ImageUploadRequestDTO, TagDTO, UploadFile} from '@common/ts/interfaces';
import {Observable} from 'rxjs';

@Injectable()
export class ImageUploadService {

  public imagesUploaded = new EventEmitter<ImageDTO[]>();

  constructor(public http: HttpClient,
              public snackBar: MatSnackBar) {
  }

  openImageUploadBar(files: UploadFile[], tags: TagDTO[] = []): void {
    this.snackBar.openFromComponent<ImageUploadProgressBarComponent, ImageUploadBarDTO>(ImageUploadProgressBarComponent, {
      panelClass: [MaterialCustomClasses.ProgressBar],
      data: {
        files,
        tags,
      },
    });
  }

  uploadImages(processedImages: ImageUploadRequestDTO[]): Observable<HttpEvent<ImageDTO[]>> {
    return this.http.request<ImageDTO[]>(new HttpRequest('POST',
      `${BACKEND_URL}/storage/image/uploadBase64/multiple`, processedImages, {
        reportProgress: true,
        withCredentials: true,
        ...setResponseMessage({
          errorMessage: imageUpload.imageUploadFailed,
        }),
      }));
  }

}
