export function linkedinInsight(partnerId: string): void {
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(partnerId);

  const script = document.getElementsByTagName('script')[0],
    tagScript = document.createElement('script');
  tagScript.type = 'text/javascript';
  tagScript.async = true;
  tagScript.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
  script.parentNode.insertBefore(tagScript, script);
}
