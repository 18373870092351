export enum ApiCommonResponseMessages {
  cantArchiveBoundListings = 'Archiving error, some of your listings are not archivable.',
  cantCullBoundListings = 'Cannot cull some of your listings.',
  cantDeleteBoundListings = 'Can not delete some of your listings.',
  cantDeleteSelectedItems = 'You can not delete the selected items.',
  cantUnarchiveBoundListings = 'Unarchiving error, some of your listings are not unarchivable, or the inventory item(s) bound to the listing(s) were deleted.',
  deleteAttachedShopLocation = 'CANNOT_DELETE_ATTACHED_SHOP_LOCATION',
  deleteDefault = 'CANNOT_DELETE_DEFAULT',
  deleted = 'DELETED',
  disableAttachedShopLocation = 'CANNOT_DISABLE_ATTACHED_SHOP_LOCATION',
  disableDefault = 'CANNOT_DISABLE_DEFAULT',
  ok = 'OK',
  underUsage = 'UNDER_USAGE',
}
