/* istanbul ignore file */

import {AppEnvironmentDTO} from '@common/ts/interfaces';

export const ENVIRONMENT: AppEnvironmentDTO = {
  production: false,
  sentryDns: 'https://30c799cb5373470aa8cc575f15af541d@sentry-staging.worldofbooks.com/5',
  linkedinPartnerId: '',
  maintenance: false,
  ebayRegistration: true,
};
