import {ImageSizeConfig} from '@common/enum/imageSizeConfig';
import {FileTypes} from '@common/ts/image-editor/types/enums';
import {Observable} from 'rxjs';

export function resizeImage(file: File): Observable<string> {
  return new Observable((obs) => {
    const fileReader = new FileReader();
    fileReader.onload = (): void => {
      const img = new Image();
      img.onload = (): void => {
        let imgWidth = img.naturalWidth,
          imgHeight = img.naturalHeight;
        const canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        if (imgWidth > imgHeight) {
          if (imgWidth > ImageSizeConfig.maxWidth) {
            imgWidth *= ImageSizeConfig.maxHeight / imgHeight;
            imgHeight = ImageSizeConfig.maxHeight;
          }
        } else if (imgHeight > ImageSizeConfig.maxHeight) {
          imgWidth *= ImageSizeConfig.maxHeight / imgHeight;
          imgHeight = ImageSizeConfig.maxHeight;
        }
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight);

        obs.next(canvas.toDataURL(FileTypes.JPEG));
        obs.complete();
      };
      img.src = String(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  });
}
