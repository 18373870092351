import {DOCUMENT} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {BooleanKeyDTO} from '@common/ts/interfaces';
import {Observable, Subscriber} from 'rxjs';

export type Scripts = 'Hubspot';

@Injectable()
export class DynamicScriptLoaderService {

  public document = inject(DOCUMENT);
  public loaded = <BooleanKeyDTO>{};
  private scriptLibrary: Record<Scripts, string> = {
    Hubspot: '//js-na1.hs-scripts.com/4644137.js',
  };

  loadScript(type: Scripts): Observable<boolean> {
    return new Observable((obs) => {
      if (!this.loaded[type]) {
        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scriptLibrary[type];
        script.async = true;
        script.onload = (): void => {
          this.loaded[type] = true;
          this.completeObservable(obs);
        };
        script.onerror = (): void => {
          this.completeObservable(obs, false);
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        this.completeObservable(obs);
      }
    });
  }

  private completeObservable(obs: Subscriber<boolean>, value = true): void {
    obs.next(value);
    obs.complete();
  }

}
