<div class="iconHolder">
  <app-hero-icon name="information-circle" class="heroIcon" />
</div>
<section>
  <span>{{ notificationText.title }}</span>
  <span>
    {{ notificationText.find }}
    <a href="https://worldofbuzz.atlassian.net/servicedesk/customer/portal/10" target="_blank">{{ notificationText.report }}</a>
  </span>
</section>
