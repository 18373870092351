import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {HeroIconComponent} from '@common/components/hero-icon/hero-icon.component';
import {ApiCommonResponseMessages} from '@common/enum/apiResponseMessages.enum';
import {FieldTypes} from '@common/enum/fieldTypes.enum';
import {AutoFilterService} from '@common/services/auto-filter/auto-filter.service';
import {AutoFilterData, StringObjectDTO} from '@common/ts/interfaces';
import {MenuRoutesService} from '@ecommerce/services/menu-routes/menu-routes.service';
import {UserDataService} from '@ecommerce/services/user-data/user-data.service';

import texts from './boundListingDeleteError';

@Component({
  selector: 'app-bound-listing-delete-error',
  templateUrl: './bound-listing-delete-error.component.html',
  styleUrl: './bound-listing-delete-error.component.scss',
  imports: [
    HeroIconComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoundListingDeleteErrorComponent {

  public boundListingDeleteErrorTexts = texts;
  public errorTypes = ApiCommonResponseMessages;
  public data = inject<StringObjectDTO>(MAT_SNACK_BAR_DATA);
  public errorType: ApiCommonResponseMessages = Object.keys(this.data)[0] as ApiCommonResponseMessages;

  constructor(public boundListingDeleteErrorComponentRef: MatSnackBarRef<BoundListingDeleteErrorComponent>,
              public routerSrv: MenuRoutesService,
              public autoFilter: AutoFilterService<AutoFilterData>,
              public userDataService: UserDataService) {
  }

  showListings(): void {
    const value = this.data[this.errorType].join(',');
    this.autoFilter.setFilters(['listings'], [{
      filters: [{
        operation: 'in',
        value,
        viewValue: value,
      }],
      prop: 'id',
      type: FieldTypes.Text,
      viewName: texts.filterName,
    }]);
    if (this.userDataService.currentUrl !== '/listings') {
      this.routerSrv.menuRoutes('listings');
    } else {
      this.userDataService.autoFilterChange.emit(true);
    }
    this.boundListingDeleteErrorComponentRef.dismiss();
  }

}
